body, html {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  background-color: #ff006a;
  font-family: "IBM Plex Sans", sans-serif; }

.error {
  text-align: center; }
  .error.content {
    max-width: 1024px;
    margin: 0 auto;
    perspective: 1200px;
    padding-top: 200px; }
  .error.code {
    position: relative;
    padding-left: 10%;
    z-index: 1;
    font-size: 17.8em;
    font-family: 'Montserrat', sans-serif;
    letter-spacing: -0.04em;
    color: #FF73AD;
    font-weight: 800;
    text-transform: uppercase;
    line-height: 0.98em;
    transform: rotateY(45deg); }
  .error .code-shadow-dark {
    text-shadow: 0px 12px 0px rgba(0, 0, 0, 0.1); }
  .error .content {
    color: #fff;
    font-size: 1.2em;
    position: relative;
    z-index: 99;
    margin-top: 60px; }
